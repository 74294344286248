import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function CategoryMenu(props) {
  const [value, setValue] = React.useState(0);
  const { categories, error, isFetching } = useSelector((state) => state.category);

  useEffect(() => {
    setValue(0);
    if(categories){
      props.onChangeCatgeory(categories[0]);
    }
  },[])
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChangeCatgeory(categories[newValue]);
  };

  return (
    
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        textColor="secondary"
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        {
            categories && categories.map((categ) => <Tab sx={{fontSize: 12, fontWeight:'bold'}} key={categ.id} label={categ.code} />)
        }
        
      </Tabs>
  );
}