import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { CATEGORY_PAGE, LOGOUT_PAGE, PRODUCT_PAGE, SALES_PAGE } from 'routes/constants';
import theme from 'themes';

const pages = ['Catégories', 'Produits', 'Ventes'];
const settings = ['Se déconnecter'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = useState(null);
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      setUser(user);
    }
  }, [])

  const handleLogout = async () => {
    navigate(LOGOUT_PAGE)
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const theme = useTheme();


  return (
    <AppBar position="fixed" color="orange">
      <Container maxWidth="xl" >
        <Toolbar disableGutters > 
          <Typography
            variant="h2"
            noWrap
            component="a"
            href={SALES_PAGE}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'white',
              textDecoration: 'none',
            }}
          >
            {`${user?.store}`}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" variant='h3'>{t('category.title')}</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" variant='h3'>{t('product.title')}</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" variant='h3'>{t('sales.title')}</Typography>
                </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {`${user?.store}`}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button size="small" color="primary" sx={{ mr: 1, fontSize:16, color:"white" }}
              onClick={() => navigate(CATEGORY_PAGE)}>
              {t('category.title')}
            </Button>
            <Button size="small" color="primary" sx={{ mr: 1, fontSize:16, color:"white"  }}
              onClick={() => navigate(PRODUCT_PAGE)}>
              {t('product.title')}
            </Button>
            <Button size="small" color="primary" sx={{ mr: 1, fontSize:16 , color:"white" }}
              onClick={() => navigate(SALES_PAGE)}>
              {t('sales.title')}
            </Button>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button
              onClick={handleLogout}
              sx={{ my: 2, color: 'white', display: 'block', fontSize: 16 }}
            >
              {`${t('actions.logout')} (${user?.username})`}
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;