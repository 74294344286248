
import axios from "axios";
import config from "../config";
import instance from "utils/AxiosInterceptor";

export const loadProducts = (page,size) => {
    return instance.get(`/product/list?page=${page}&size=${size}`);
}

export const search = (query) => {
  return instance.post(`/product/search`, JSON.stringify(query));
};

export const listStockEntreByProduct = (id) => {
  return instance.get(`/product/entre/list/${id}`);
};

export const findProductById = (id) => {
  return instance.get(`/product/${id}`);
}

export const findProductByCategory = (id, page,size) => {
  return instance.get(`/product/category/${id}?page=${page}&size=${size}`);
}

export const findProductBySubCategory = (id, page,size) => {
  return instance.get(`/product/subcategory/${id}?page=${page}&size=${size}`);
}

export const saveProduct = (request) => {
    return instance.post(`/product/create`, JSON.stringify(request));
}

export const saveEntry = (request) => {
  return instance.post(`/product/entre/new`, JSON.stringify(request));
}

export const updateEntry = (updated) => {
  return instance.put(`/product/entre/update`, JSON.stringify(updated));
}

export const findProductByBarcode = (barcode) => {
  return instance.get(`/product/barcode/${barcode}`);
}

export const deleteProduct = (id) => {
  return instance.delete(`/product/delete/${id}`);
};

export const updateProduct = (updated) => {
  return instance.put(`/product/update`, JSON.stringify(updated));
}

export const uploadImageProduct = async (formData) => {
  const authUser = sessionStorage.getItem("authUser");
  const parsedAuthUser = JSON.parse(authUser);
  let headers = { "Content-type": "multipart/form-data" , "Authorization": `Bearer ${parsedAuthUser.accessToken}`,}
  return await axios.post(`${config.baseURL}/product/upload`, formData, { headers: headers });
}

export const downloadTemp = async () => {
  const authUser = sessionStorage.getItem("authUser");
  const parsedAuthUser = JSON.parse(authUser);
  let headers = { "Content-type": "multipart/form-data" , "Authorization": `Bearer ${parsedAuthUser.accessToken}`,}

  return await axios.get(`${config.baseURL}/product/template/download`, { headers: headers })
};

export const uploadData = async (formData) => {
  const authUser = sessionStorage.getItem("authUser");
  const parsedAuthUser = JSON.parse(authUser);
  let headers = { "Content-type": "multipart/form-data" , "Authorization": `Bearer ${parsedAuthUser.accessToken}`,}
  return await axios.post(`${config.baseURL}/product/data/upload`, formData, { headers: headers });
};

export const setPrincipleImage = (id) => {
  return instance.put(`/product/image/principle/${id}`);
}

export const setPrincipleProductImage = (id) => {
  return instance.put(`/product/image/principle/${id}`);
}

export const deleteImage = (fileId) => {
  return instance.delete(`/product/delete/${fileId}`);
}