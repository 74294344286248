import * as React from 'react';
import {
  DataGrid
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import config from 'config';
import { fontWeight } from '@mui/system';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 2,
  // backgroundColor:"#e0e0e0",
  // color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  // '& .MuiDataGrid-columnsContainer': {
  //   backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  // },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
  },
  '& .MuiDataGrid-cell': {
    // color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    fontSize: '20px', 
    fontWeight:"bold"
      
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
}));

export default function StyledTable(props) {

  return (
    <div style={{ height:props.height ?? 400, width: '100%' }}>
      <StyledDataGrid
        sx={{boxShadow:2,"& .MuiDataGrid-columnHeaders": {
            //backgroundColor: "#347deb",
            color: "#fff",
            fontSize: props?.fontSize ?? config.tableHeaderFontSize,
          }
          }}
        {...props}
      />
    </div>
  );
}
