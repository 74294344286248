import styled from "@emotion/styled";
import { Alert, Backdrop, Box, Button, Card, CardContent, CardMedia, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, ListSubheader, Paper, Radio, RadioGroup, Snackbar, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "sagas/productSlice";
import Calculator from "./Calculator";
import CategoryMenu from "./CategoryMenu";
import ProductList from "./ProductList";
import { orderActions } from "sagas/orderSlice";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import CustomInputDialog from "ui-component/CustomInputDialog";
import { getChipColor, getStoreType } from "utils/helpers";
import config from "config";
import ClearIcon from '@mui/icons-material/Clear';
import { categoryActions } from "sagas/categorySlice";
import { AddCircleRounded, Minimize, MinimizeRounded, MinimizeSharp, RemoveCircleRounded } from "@mui/icons-material";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#e0e0e0",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: 2
}));

const vertical = 'top';
const horizontal = 'right';

export default function SaleContainer() {
    const initForm = {
        lines: [],
        code: "",
        total: 0,
        payedAmount: 0,
        status: "",
        deliveryType: "EMPORTER"
    }
    const [formState, setForm] = useState(initForm);
    const [category, setCategory] = useState(null);
    const [line, setLineForUpdate] = useState(null);
    const [data, setData] = useState(null)
    const { products } = useSelector(state => state.product);
    const { categories } = useSelector(state => state.category);

    const [confirmDialog, setConfirmDialog] = useState(false);
    const [success, setSuccess] = useState(false);
    const [selected, setSelected] = useState(1);
    const [error, setError] = useState({ flag: false, message: "" });
    const { units } = useSelector((state) => state.data);
    const [barcode, setBarcode] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const { status } = useSelector((state) => state.data);
    const [user, setUser] = useState(null);

    let { id } = useParams();

    const handleCategoryChange = (category) => {
        setCategory(category)
    }

    const getUnit = (code) => {
        return units.find(u => u.code === code)?.descriptionAr
    }

    const isRestaurant = getStoreType() === "RESTAURANT";

    const formik = useFormik({
        initialValues: { ...formState, },
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            if (id || data?.id > 0) {
                let payload = {
                    data: {
                        id: id ?? data?.id,
                        ...values,
                    },
                    onSuccess: (data) => {
                        setSuccess(true)
                        let payload = {
                            id: data.id,
                            onSuccess: (data) => {
                                setForm({ ...data })
                                setData({ ...data });
                                // if (data.status === 'PAYE') {
                                //     printReceipt(data?.id);
                                // }
                            }
                        }
                        dispatch(orderActions.find(payload));
                    },
                    onError: () => setError({ flag: true, message: t('messages.saveSaleError') })
                }
                dispatch(orderActions.update(payload))
            } else {

                let payload = {
                    data: {
                        id: 0,
                        ...values,
                    },
                    onSuccess: (data) => {
                        setSuccess(true)
                        let payload = {
                            id: data.id,
                            onSuccess: (data) => {
                                setForm({ ...data })
                                setData({ ...data });
                            }
                        }
                        dispatch(orderActions.find(payload));
                    },
                    onError: () => setError({ flag: true, message: t('messages.saveSaleError') })
                }

                dispatch(orderActions.create(payload))
            }

        },
        onReset: () => {

        }
    })

    useEffect(() => {
        if (categories) {
            setCategory(categories[0])
        }
    }, [categories]);

    useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const user = JSON.parse(sessionStorage.getItem("authUser"));
            setUser(user);
        }

        setLoading(true);
        setTimeout(() => {
            dispatch(categoryActions.fetchCategories({ page: 0, size: 10000 }));

            if (id) {
                let payload = {
                    id,
                    onSuccess: (data) => {
                        formik.setValues({ ...data })
                        setData({ ...data });
                        setLoading(false)
                    }
                }
                dispatch(orderActions.find(payload));
            } else {
                formik.setValues({ ...initForm })
                findOrderNumber()
            }
        }, [1500]);

    }, [])

    useEffect(() => {
        return () => {
            formik.setValues({ ...initForm })
        };
    }, []);

    const findOrderNumber = () => {
        let payload = {
            onSuccess: (data) => {
                formik.setValues({ ...initForm, code: data })
                setData(null)
                setLoading(false)
            },
        }

        dispatch(orderActions.getOrderNumber(payload))
    }

    const addLine = (product, qte) => {
        console.log(product);

        let existing = formik.values.lines.find(line => {
            return line.product.id === product.id
        });
        let line = null;
        console.log(existing);

        if (existing && existing.qte === 1 && qte < 0) {
            deleteLine(existing);
        } else {

            if (existing) {
                existing = { ...existing, qte: existing.qte + qte, salePrice: product.salePrice, amount: product.salePrice * (existing.qte + qte) };
            } else {
                console.log(existing);
                line = {
                    product: { ...product },
                    qte: qte,
                    amount: product.salePrice,
                    salePrice: product.salePrice,
                }
            }

            let lines = formik.values.lines;
            if (existing) {
                lines = lines.filter(o => o.product.id !== product.id);
                lines.push(existing);
            } else {
                lines.push(line);
            }

            const total = [...new Set(lines.map(item => item.amount))].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            formik.setFieldValue('lines', lines);
            formik.setFieldValue('total', total)
            setLineForUpdate(null)
        }
    }

    const updateLine = (line) => {
        setLineForUpdate(line)
    }

    const handleUpdate = (line, qte) => {

        let existing = { ...line, qte: qte, amount: line.product.salePrice * qte };
        let lines = formik.values.lines.filter(o => o.product.id !== line.product.id);
        lines.push(existing);
        const total = [...new Set(lines.map(item => item.amount))].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        formik.setFieldValue('lines', lines);
        formik.setFieldValue('total', total)
    }



    const deleteLine = (line) => {
        let lines = formik.values.lines.filter(o => o.product.id !== line.product.id);
        const total = [...new Set(lines.map(item => item.amount))].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        formik.setFieldValue('lines', lines);
        formik.setFieldValue('total', total)
    }

    const findProduct = (b) => {
        setBarcode(b)
        if (b.length > 10) {
            let payload = {
                barcode: b,
                onSuccess: (product) => {
                    setBarcode("")
                    addLine(product, 1);
                },
                onError: (error) => {
                    setBarcode("")
                    setError({ flag: true, message: t('messages.noProduct') })
                }
            }
            dispatch(productActions.findByBarcode(payload))
        }
    }

    const handleSave = () => {
        setConfirmDialog(false);
        formik.handleSubmit()
    }

    const confirmSave = () => {
        if (formik.values.lines.length === 0) {
            setError({ flag: true, message: t('messages.noArticles') })
        } else {
            formik.handleSubmit()
        }
    }

    const confirmSaveAndPay = () => {
        if (data && data?.lines?.length === 0) {
            setError({ flag: true, message: t('messages.noArticles') })
        }
        else if (!data && formik.values.lines.length === 0) {
            setError({ flag: true, message: t('messages.noArticles') })
        } else {
            setConfirmDialog(true);
        }
    }

    const printReceipt = (id) => {
        let payload = {
            id: id,
            onSuccess: (response) => {
                const pdfWindow = window.open();
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(response) +
                    "'></iframe>"
                );
            },
        }

        dispatch(orderActions.printReceipt(payload))
    }

    const printReceiptIntern = (id) => {
        let payload = {
            id: id ?? data.id,
            onSuccess: (response) => {
                const pdfWindow = window.open();
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(response) +
                    "'></iframe>"
                );
            },
        }

        dispatch(orderActions.printReceiptIntern(payload))
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
                <Grid container spacing={1}>
                    {(loading) &&
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>}
                    <Grid item xs={1}>
                        <Paper sx={{ p: 1, backgroundColor: "#e0e0e0" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Button variant="contained" onClick={() => navigate('/sales')} sx={{ height: '10%' }} size="small" >{t('actions.orderList')}</Button>
                                <Button color="success" variant="contained" onClick={() => confirmSaveAndPay()} sx={{ height: '10%' }} size="small" disabled={data?.status === 'PAYE'} >{t('actions.pay')}</Button>
                                <Button color="success" variant="contained" onClick={() => confirmSave()} disabled={data?.status === 'PAYE'} size="small" sx={{ height: '10%' }} >{t('actions.save')}</Button>
                                {isRestaurant && <Button color="warning" variant="contained" onClick={() => printReceiptIntern(formik.values.id)} disabled={data?.status === 'PAYE' || !formik.values.id} size="small" sx={{ height: '10%' }} >{t('actions.printReceiptI')}</Button>}
                                <Button color="error" variant="contained" onClick={() => {
                                    formik.setValues({ ...initForm, code: formik.values.code })
                                    setData(null)
                                }} fu size="small" sx={{ height: '10%' }} disabled={id && id > 0} >{t('actions.clear')}</Button>
                                <Button color="warning" variant="contained" onClick={() => printReceipt(formik.values.id)} sx={{ height: '10%' }} disabled={formik.values.id == 0 || !formik.values.id} size="small">{t('actions.printReceipt')}</Button>
                                <Button color="success" variant="contained" onClick={() => {
                                    formik.setValues({ ...initForm })
                                    findOrderNumber();
                                }} sx={{ height: '10%' }} size="small"  >{t('actions.newOrder')}</Button>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Paper elevation={0} sx={{ p: 1, backgroundColor: "#e0e0e0" }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, px: 2 }}>
                                        <Typography variant="h2" >{t('text.orderNum')} {id ? data?.code : formik.values.code}</Typography>
                                        {formik.values.status?.length > 0 && <Chip title={status.find(s => s.code === formik.values.status)?.descriptionAr} label={status.find(s => s.code === formik.values.status)?.descriptionAr}
                                            sx={{
                                                boxShadow: 2, fontSize: config.chipFontSize,
                                                color: '#fff',
                                                mx: 3,
                                                backgroundColor: getChipColor(formik.values.status)
                                            }}
                                            variant="contained" />}
                                        {user?.type === 'RESTAURANT' &&
                                            <FormControl disabled={data?.status === 'PAYE'}>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="deliveryType"
                                                    value={formik.values.deliveryType}
                                                    onChange={(event) => formik.setFieldValue("deliveryType", event.target.value)} >
                                                    <FormControlLabel label={<Typography variant="subtitle1">EMPORTER</Typography>} value="EMPORTER" control={<Radio size="small"/>}/>
                                                    <FormControlLabel value="SURPLACE" control={<Radio size="small"/>} label={<Typography variant="subtitle1">SUR PLACE</Typography>} />
                                                </RadioGroup>
                                            </FormControl>}

                                    </Box>

                                    <List dense={false} sx={{ maxHeight: 400, position: 'relative', overflow: 'auto', }}>
                                        {formik.values.lines.map((line, index) =>
                                            <ListItemButton selected={selected === index} disabled={data?.status === 'PAYE'} onClick={() => {
                                                updateLine(line);
                                                setSelected(index)
                                            }
                                            }>
                                                <IconButton variant="contained" onClick={() => deleteLine(line)} color="error" size="small" ><ClearIcon /></IconButton>
                                                <IconButton variant="contained" onClick={() => addLine(line.product, 1)} color="success" size="small" ><AddCircleRounded /></IconButton>
                                                <IconButton variant="contained" onClick={() => addLine(line.product, -1)} color="orange" size="small" ><RemoveCircleRounded /></IconButton>

                                                <Typography variant="h4" InputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}>{line.amount} {t('text.currency')}</Typography>
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: '12px', fontWeight: 'bold' }}
                                                    secondaryTypographyProps={{ fontSize: '10px', fontWeight: 'bold' }}
                                                    id="switch-list-label-wifi"
                                                    primary={`${line.product.label}`}
                                                    secondary={`${line.qte}  x  (${line?.salePrice} ${t('text.currency')} / ${getUnit(line.product.unitOfMeasure)})  `}
                                                />


                                                <Card sx={{ mx: 1, boxShadow: 1, width: 80 }} >
                                                    <CardContent>
                                                        <CardMedia
                                                            component="img"
                                                            image={line.product.photo?.path} />
                                                    </CardContent>
                                                </Card>
                                            </ListItemButton >,
                                        )}

                                    </List>
                                    <Divider sx={{
                                        "&::before, &::after": {
                                            borderColor: "#091242",
                                        }, color: "#091242", my: 2
                                    }} textAlign="left">

                                    </Divider>
                                    <Typography variant="h1" textAlign="right">{t('text.total')} {data ? data.total : formik.values.total} {t('text.currency')}</Typography>
                                </Paper>
                            </Grid>

                            {/* <Grid item xs={12}>
                                <Calculator line={line} onUpdate={handleUpdate} onDelete={deleteLine} />
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={7}>
                        {/* <Item>
                        <Box
                            sx={{ m: 1, }}
                            autoComplete="off">
                            <TextField name="barcode" value={barcode} fullWidth id="outlined-basic" onChange={event => findProduct(event.target.value)} label={t('text.searchBarcode')} variant="outlined" autoFocus />
                        </Box>
                    </Item> */}
                        <Item >
                            <CategoryMenu onChangeCatgeory={handleCategoryChange} />
                            <ProductList categoryId={category?.id} onSelect={addLine} disabled={id && id > 0} />
                        </Item>
                    </Grid>
                </Grid>
            </form>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ backgroundColor: "#078d3f", color: "white", fontSize: 20, fontWeight: 'bold', width: '100%' }}>
                    {t('messages.orderSaved')}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={error.flag} autoHideDuration={5000} onClose={() => setError({ flag: false, message: "" })}>
                <Alert onClose={() => setError({ flag: false, message: "" })} severity="error" sx={{ backgroundColor: "#c62828", color: "white", fontSize: 20, fontWeight: 'bold', width: '100%' }}>
                    {error.message}
                </Alert>
            </Snackbar>

            {confirmDialog && <CustomInputDialog
                open={confirmDialog}
                close={() => {
                    formik.setFieldValue('payedAmount', 0);
                    setConfirmDialog(false);
                }}
                header={t('text.payementTitle')}
                formik={formik}
                data={data}
                message={t('text.paymentMsg')}
                cancelText={t('actions.cancel')}
                confirmText={t('actions.confirm')}
                cancelOnClick={() => {
                    formik.setFieldValue('payedAmount', 0);
                    setConfirmDialog(false);
                }}
                confirmOnClick={handleSave}
            />}
        </>
    )
}

const styles = theme => ({
    Card: {
        width: 40,
        margin: 'auto'
    },
    Media: {
        height: 100,
        width: '20%'
    }
});