import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ar:{
    translation:{
      product: {
        title:"منتجات",
        subCategory: "تحت الفئة",
        category: "فئة",
        unitOfMeasure: "وحدة القياس",
        label: "منتج",
        barcode: "الرمز الشريطي المرتبط بهذا المنتج (اختياري)",
        buying: "سعر الشراء",
        salePrice: "سعر البيع",
        qteStock: "الكميه في المخزن",
        availSale: "متاحة للبيع",
        withBarcode: "المنتج مع الباركود؟",
        imageMsg: "صورة المنتج",
        newProduct:"أضف منتجًا",
      },
      login:{
        title:"تسجيل الدخول",
        hint:"أدخل تسجيل الدخول الخاص بك",
        username:"اسم المستخدم",
        storeCode:"رمز المتجر",
        password:"كلمة المرور",
        button:"تسجيل الدخول",
      },
      sales:{
        title:"المبيعات",
        deliveryType:"نوع التسليم"
      },
      messages:{
        noArticles:"لم يتم إضافة أي منتج للطلب",
        saveSaleError:"حدث خطأ أثناء حفظ الطلب. حاول ثانية",
        noProduct:"لم يتم العثور على منتج مع هذا الباركود",
        orderSaved:"تم حفظ الطلب",
        saveSuccess:"تم حفظ المعلومات بنجاح",
        confirmDelete:"هل تريد حذف المنتج؟",
        confirmTitle:"تأكيد",
        loginError:"خطأ في تسجيل الدخول"

      },
      actions:{
        add:"اختار",
        cancel:"إلغاء",
        confirm:"تأكيد",
        pay:"الدفع",
        newOrder:  "طلب جديد",
        modifier:"راجع",
        orderList: "قائمة المبيعات",
        clear: "امسح الكل",
        printReceipt:"التذكرة",
        logout:"تسجيل الخروج",
        voir:"راجع",
        printReceipt:"تذكرة زبون",
        printReceiptI:"تذكرة داخلية",
        save:"حفظ",
        details:"تفاصيل"
      },
      category:{
        newCategory:"أضف فئة",
        title:"الفئات",
        code:"رمز",
        description:"وصف"
      },
      
      text:{
        searchBarcode:"البحث عن طريق الباركود",
        total: 'المجموع',
        currency:"درهم",
        paymentMsg: "أدخل المبلغ الذي دفعه المشتري واحسب العائد",
        payementTitle:"دفع إجمالي المشتريات",
        orderNum:"رقم الطلب",
        payedAmount:"المبلغ المدفوع",
        rendu:"يرجع",
        orderDate:"تاريخ الطلب",
        status:"حالة السداد",
        orderList: "قائمة المبيعات",
        actions:"أجراءات",
        totalEarnings:"مجموع المبيعات ",
        totalOrders: "عدد المبيعات",
        totalIncome:"إجمالي الأرباح",
        today:"اليوم",
        week:"الأسبوع الحالي",
        month:"الشهر الحالي",
        year:"السنة الحالية",
        orders:"مبيعات",
        noSales:"لم تقم بأي مبيعات حتى الآن",
        salePrice:"سعر البيع",
        buyPrice:"سعر الشراء"
      },
      stock:{
        newEntry:"أضف كمية",
        product:"منتج",
        entreDate:"تاريخ الدخول",
        lastModifiedDate:"تاريخ آخر تحديث",
        newQuantity:"كمية جديدة ",
        beforeQuantity:"الكمية المتبقية",
        currentQuantity:"الكمية الحالية",
        buyingPrice:"سعر الشراء",
        salePrice: "سعر البيع",
        currency:"درهم",
      },
    }
  },
  en: {
    translation: {

    },
  },

  fr:{
    translation:{
      stock:{
        newEntry:"Ajouter une quantité",
        product:"Produit",
        entreDate:"Date d'entrée",
        lastModifiedDate:"Date dernière mise à jour",
        newQuantity:"Nouvelle quantité ",
        beforeQuantity:"Quantité restante",
        currentQuantity:"Quantité actuelle",
        buyingPrice:"Prix d'achat",
        salePrice: "Prix de vente",
        currency:"DH",
      },
      product: {
        newProduct:"Ajouter un article",
        title:"Produits",
        category: "Catégorie",
        unitOfMeasure: "Unité de mesure",
        label: "Nom d'article",
        barcode: "Code à barres",
        buying: "Prix d'achat",
        salePrice: "Prix de vente",
        qteStock: "Quantité en stock",
        imageMsg: "Photo d'article"
      },
      category:{
        newCategory:"Ajouter un catégorie",
        title:"Catégories",
        code:"Code",
        description:"Description"
      },
      bilan:{
        title:"البيانات"
      },
      register:{
        title:"انشاء حساب جديد",
        shopName:"اسم المتجر",
        username:"اسم المستخدم",
        password:"رمز  المتجر",
        button:"تسجيل",
        haveAccount: "هل لديك حساب؟"
      },
      login:{
        title:"Se connecter",
        hint:"Saisissez votre nom d'utilisateur et code de magasin",
        username:"Nom d'utilisateur",
        password:"Code magasin",
        button:"Login",
      },
      sales:{
        title:"Ventes"
      },
      text:{
        searchBarcode:"Scannez code à barres....",
        total: 'Total',
        currency:"DH",
        paymentMsg: "Saisissez le montant payé par l'acheteur et calculez le rendu",
        payementTitle:"Paiement du total de l'achat",
        orderNum:"N° de commande",
        payedAmount:"Montant payé",
        rendu:"Rendu",
        orderDate:"Date de commande",
        status:"Statut de paiement",
        orderList: "Ventes",
        actions:"Actions",
        totalEarnings:"Total des ventes",
        totalOrders: "Nombre de ventes",
        totalIncome:"Revenu total",
        today:"Aujourd'hui",
        week:"Semaine",
        month:"Mois",
        year:"Année",
        orders:"Ventes",
        noSales:"Vous n'avez pas encore réalisé de ventes",
        salePrice:"Prix de vente",
        buyPrice:"Prix d'achat"
      },
      messages:{
        loginError:"Erreur de connexion",
        noArticles:"Aucun produit n'a été ajouté à la commande",
        saveSaleError:"Une erreur s'est produite lors de l'enregistrement de la commande. Réessayez",
        noProduct:"Aucun produit n'a été trouvé avec ce code-barres",
        updateEntryError:"Vous ne pouvez pas mettre à jour cette entrée du référentiel..",
        saveSuccess:"Les informations ont été enregistrées avec succès",
        orderSaved:"تم حفظ الطلب"
      },
      actions:{
        save:"Confirmer",
        clear:"Annuler",
        add:"Ajouter",
        cancel:"Annuler",
        confirm:"Confirmer",
        voir:"Consulter",
        pay:"Payer",
        newOrder:  "Nouvelle commande",
        modifier:"Modifier",
        orderList: "Ventes",
        clear:"Annuler",
        printReceipt:"Ticket client",
        downloadAll:"تحميل المنتجات",
        downloadModel:"تحميل النموذج",
        logout:"Déconnexion",
        printReceiptI:"Ticket interne"
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ar", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
