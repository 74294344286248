import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

// assets
import PaymentsIcon from '@mui/icons-material/Payments';
import { useTranslation } from 'react-i18next';
import { orderActions } from 'sagas/orderSlice';
import { useDispatch } from 'react-redux';
import { Download } from '@mui/icons-material';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.orange.dark,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
        position: 'relative',
        zIndex: 5
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: "orange",
        borderRadius: '50%',
        zIndex: 1,
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        width: 210,
        height: 210,
        background: "orange",
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const EarningCard = ({ isLoading, total }) => {
    const theme = useTheme();

    const { t } = useTranslation('translation');
    const [report, setReport] = useState(null);
    const dispatch = useDispatch();
    const [timeValue, setTimeValue] = useState(1);

    const handleChangeTime = (event, newValue) => {
        setTimeValue(newValue);
        if (newValue === 1) {
            let current = new Date()
            let tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1);

            let payload = {
                query: {
                    dateDu: current.toISOString().split('T')[0],
                    dateAu: tomorrow.toISOString().split('T')[0],
                },
                onSuccess: (data) => {
                    setReport(data)
                }
            };
            dispatch(orderActions.report(payload));

        } else if (newValue === 2) {
            let debut = getMonday(new Date());
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (data) => {
                    setReport(data)
                }
            };
            dispatch(orderActions.report(payload));

        } else if (newValue === 3) {
            let date = new Date();
            let debut = new Date(date.getFullYear(), date.getMonth(), 1);
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (data) => {
                    setReport(data)
                }
            };
            dispatch(orderActions.report(payload));

        } else if (newValue === 4) {
            let date = new Date();
            let debut = new Date(date.getFullYear(), 0, 1);
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (data) => {
                    setReport(data)
                }
            };
            dispatch(orderActions.report(payload));
        }

    };

    const handleDownload = () => {
        
        if (timeValue && timeValue === 1) {
            let current = new Date()
            let tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1);

            let payload = {
                query: {
                    dateDu: current.toISOString().split('T')[0],
                    dateAu: tomorrow.toISOString().split('T')[0],
                },
                onSuccess: (response) => {
                    const pdfWindow = window.open();
                    pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                        encodeURI(response) +
                        "'></iframe>"
                    );
                },
            };
            dispatch(orderActions.report(payload));

        } else if (timeValue &&  timeValue === 2) {
            let debut = getMonday(new Date());
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (response) => {
                    const pdfWindow = window.open();
                    pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                        encodeURI(response) +
                        "'></iframe>"
                    );
                },
            };

            dispatch(orderActions.report(payload));

        } else if (timeValue &&  timeValue === 3) {
            let date = new Date();
            let debut = new Date(date.getFullYear(), date.getMonth(), 1);
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (response) => {
                    const pdfWindow = window.open();
                    pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                        encodeURI(response) +
                        "'></iframe>"
                    );
                },
            };
            dispatch(orderActions.report(payload));

        } else if (timeValue &&  timeValue === 4) {
            let date = new Date();
            let debut = new Date(date.getFullYear(), 0, 1);
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (response) => {
                    const pdfWindow = window.open();
                    pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                        encodeURI(response) +
                        "'></iframe>"
                    );
                },
            };
            dispatch(orderActions.report(payload));
        }
    };

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    return (
        <>
            {isLoading ? (
                <SkeletonEarningCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 1 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                ...theme.typography.commonAvatar,
                                                ...theme.typography.largeAvatar,
                                                backgroundColor: theme.palette.primary[800],
                                                color: '#fff',
                                                mt: 1
                                            }}
                                        >
                                            <PaymentsIcon fontSize="inherit" />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disableElevation
                                            variant={timeValue === 1 ? 'contained' : 'text'}
                                            size="small"
                                            sx={{ color: 'inherit', }}
                                            onClick={(e) => handleChangeTime(e, 1)}
                                        >
                                            {t('text.today')}
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant={timeValue === 2 ? 'contained' : 'text'}
                                            size="small"
                                            sx={{ color: 'inherit', }}
                                            onClick={(e) => handleChangeTime(e, 2)}
                                        >
                                            {t('text.week')}
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant={timeValue === 3 ? 'contained' : 'text'}
                                            size="small"
                                            sx={{ color: 'inherit', }}
                                            onClick={(e) => handleChangeTime(e, 3)}
                                        >
                                            {t('text.month')}
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant={timeValue === 4 ? 'contained' : 'text'}
                                            size="small"
                                            sx={{ color: 'inherit' }}
                                            onClick={(e) => handleChangeTime(e, 4)}
                                        >
                                            {t('text.year')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography sx={{ fontSize: '2.5rem', fontWeight: 500, mr: 10, }}>
                                            {` ${!!report ? report?.totalEarning : total} ${t('text.currency')}`}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography
                                            sx={{
                                                fontSize: '2rem',
                                                fontWeight: 500,
                                                color: theme.palette.primary[200],
                                                mr: 10,
                                            }}
                                        >
                                            {t('text.totalEarnings')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {/* <Button
                                            disableElevation
                                            variant="contained"
                                            size="small"
                                            sx={{ color: 'inherit', m: 1 }}
                                            onClick={(e) => handleChangeTime(e, 4)}
                                        >
                                            {t('actions.details')}
                                        </Button> */}

                                        <IconButton
                                            className="btn-upload"
                                            color="inherit"
                                            variant="contained"
                                            component="span"
                                            onClick={(e) => handleDownload()}
                                        >
                                            <Download />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

EarningCard.propTypes = {
    isLoading: PropTypes.bool
};

export default EarningCard;
